
import { PushNotificationSchema } from "@capacitor/push-notifications";
import { Component, Vue } from "vue-property-decorator";

interface SnackbarInterface {
  snackbar: boolean;
  snack: Record<string, unknown>;
}

type Unwatcher = () => void;

@Component
export default class Snackbar extends Vue implements SnackbarInterface {
  private _unwatchSnack!: Unwatcher;

  snackbar: boolean = false as boolean;

  snack = {
    notification: undefined,
    status: null,
    text: null
  };

  created() {
    this._unwatchSnack = this.$store.watch(
      state => state.snack.snackMessage,
      snack => {
        if (snack) {
          this.snackbar = true;
          this.snack = snack;

          this.$store.commit("snack/setSnack", null);
        }
      }
    );
  }

  beforeDestroy() {
    this._unwatchSnack();
  }

  push(notification?: PushNotificationSchema) {
    this.$router
      .push({
        name: notification?.data.route,
        params: { id: notification?.data.id }
      })
      .catch(e => e);
  }
}
