
import { Component, Vue } from "vue-property-decorator";
import Snackbar from "@/app/modules/_global/components/Snackbar.vue";

@Component({
  components: {
    Snackbar
  }
})
export default class ChildLayout extends Vue {}
